@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,700;1,100;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@700&display=swap');
*{
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #555; 
}

$f-primary-color:white;
$f-secondary-color:DARKGOLDENROD;


body{
    background: whitesmoke !important;
}

//Nav styling
.navigation-wrapper{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    background: #262626;
    padding: 0.5% 5%;
    z-index: 99;
    .logo{
        font-family: 'Cairo', sans-serif;
        width: 50%;
        a{
            color: white;
            font-size: 2rem;
            user-select: none;
            text-decoration: none;
            img{
                width: 120px;
                height: auto;

            }

        }
        @media (max-width:500px) {
            width: 100%;
            text-align: center;
        }
    }
    .links{
        width: 60%;
        font-size: 1.5rem;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
        a{
            color: white;
            text-decoration: none;
            font-family: 'Montserrat', sans-serif;
            &.active{
                color: $f-secondary-color;
            }
            &:last-child{
                padding: 0.2rem 0.5rem;
                border-radius: 20px;
                background: $f-secondary-color;
                color: white;
            }
        }
        @media (max-width:700px) {
            display: none;
        }
    }
    .mobileNav{
        background: #262626;
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 98;
        display: flex;
        justify-content: space-around;
        padding: 0.2rem;
        a{
            color: $f-primary-color;
            svg{
                font-size: 2rem;
            }
        }
        @media (min-width:700px) {
            display: none;
        }
    }
}

.hero-wrapper{
    height: 95vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-width:500px) {
        height: 70vh;
    }
    .hero-background{
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #232323;
        background-size: cover;
        background-position: center;
        z-index: -1;
        img{
            filter: brightness(65%) blur(1px);
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    .hero-content{  
        width: 80%;
        height: 20%;
        margin: 6% auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        font-family: 'Montserrat', sans-serif;
        text-align: center;
        @media (max-width: 1000px){
            margin: auto;
            width: 95%;
        }
        @media (max-width:500px) {
            height: 100%;
        }
        p{
            font-size: 5rem;
            color: white;
            width: 70%;
            @media (max-width: 1000px){
                font-size: 4rem;
                width: 100%;
                margin-bottom: 6%;
            }
            @media (max-width: 500px){
                font-size: 3.5rem;
                width: 100%;
                margin-bottom: 6%;
            }
            span{
                color: $f-secondary-color;
                font-style: italic;
                font-weight: 500;
            }
        }
        a{
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 500;
            color: white;
            font-size: 2rem;
            background: $f-secondary-color;
            border-radius: 20px;
            padding: 1rem 2rem;
            @media (max-width:500px) {
                font-size: 1rem;
                padding: 0.75rem 1.5rem;
            }
        }
    }
}

.subhero-wrapper{
    padding: 5% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    h1{
        margin: 3% auto 2%;
        text-align: center;
        @media (max-width:500px) {
            font-size: 2rem;
        }
    }
    .showcase{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 2% auto;
        height: 50vh;
        width: 80%;
        @media (max-width:500px) {
            flex-direction: column;
        }
        img{
            height: 100%;
            width: 50%;
            object-fit: cover;
            object-position: center;
            @media (max-width:500px) {
                width: 100%;
                height: 70%;
            }
        }
        .content{
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media (max-width:500px) {
                height: 30%;
                width: 100%;
                align-items: center!important;
                text-align: center;
            }
            &.inv{
                align-items: flex-end;
                order: -1;
                @media (max-width:500px) {
                    order: 0;
                }
            }
            a{
                font-family: 'Montserrat', sans-serif;
                text-decoration: none;
                border-radius: 20px;
                background: #262626;
                color: white;
                padding: 5%;
                width: 75%;
                margin: 1%;
                font-size: 2rem;            
                @media (max-width:500px) {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

.footer-wrapper{
    z-index: 99;
    font-family: 'Montserrat', sans-serif;
    background: #262626;
    color: white;
    padding: 1% 10%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    @media (max-width:500px) {
        margin-bottom: 8%;
        z-index: 99;
        width: 100%;
    }
    .topscroll{
        user-select: none;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        @media (max-width:500px) {
            display: none;
        }
        &:hover{
            svg{
                transform: scale(1.2);
            }
        }
    }
    .social{
        display: flex;
        a{
            margin: 5%;
            color: white;
            transition: all 0.5s ease-in-out;
            svg{
                font-size: 1.9rem;
            }
            &:hover{
                color:$f-secondary-color;
            }
        }
    }
    .cc-license{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        @media (max-width:500px){
            p{
                display: none;
            }
        }
        a{
            color: $f-secondary-color;
        }
    }

}

.gallery-page{
    position: relative;
    padding-bottom: 3%;
    .carrousel-wrapper{
        width: 100%;
        height: 20%;
        margin: 0 auto 3%;
        .carousel-inner{
            .carousel-item{
                // background: #2626260e;   
                position: relative;
                .background{
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    filter: blur(4px) brightness(80%);
                    pointer-events: none;
                }
                &:hover{
                    .foreground{
                        filter: brightness(50%);
                    }
                    .carousel-caption{
                        display: inline!important;
                    }
                }
                img{
                    height: 50vh;
                    width: 100%;
                    object-fit: contain;
                    transition: all 0.2s ease-out;
                }
                .carousel-caption{
                    color: white;
                    font-weight: 700;
                    display: none!important;
                }
            }
        }
        
    }
    .gallery-wrapper{
        .controls{
            background: #262626;
            padding: 1% 10%;

            @media (max-width:500px) {
                display: none;
            }
            .links{
                display: flex;
                justify-content: space-around;
                @media (max-width:850px) {
                    display: none;
                }
                a{
                    color: white;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                }

            }
            .dropdown{
                display: none;
                button{
                    color: white;
                }
                .link{
                    color: black!important;
                }
                @media (max-width:850px) {
                 display: block;   
                }
            }
        }
        .gallery{
            width: 80%;
            margin: 3% auto;
            display: grid;
            grid-template-columns: repeat(auto-fit,minmax(140px,1fr));
            grid-auto-rows: 180px;
            grid-gap: 0.4rem;

            @media screen and (min-width:750px){
                grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
                grid-auto-rows: 300px;
                .wide{
                    grid-column: span 2 / auto;
                }
                
                .tall{
                    grid-row: span 2 / auto;
                }
                .large{
                    grid-column: span 2 / auto;
                    grid-row: span 2 / auto;
                }
            }




            .image-wrapper{
                position: relative;
                cursor: pointer;
                .focused{
                    display: flex; 
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    position: fixed; 
                    z-index: 98; 
                    left: 0;
                    top: 0;
                    width: 100%; 
                    height: 100%;      
                    background: rgba(0,0,0,0.70); 
                    padding: 2%;
                    .focuesedimage-info{
                        display: none;
                    }
                    img{
                        object-fit: contain !important;
                        z-index: 99;
                        filter: brightness(100%) !important;
                    }
                    @media (max-width:500px) {
                        .focuesedimage-info{
                            display: block;
                            text-align: center;
                            a{
                                color: $f-primary-color;
                                font-size: 2rem;
                            }
                        }
                        img{
                            height: auto;
                        }
                    }
                }
                &:hover{
                    img{
                        filter: brightness(20%);
                    } 
                    .image-info{
                        @media (min-width:700px) {
                            display: flex;
                        }
                    }
                }
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    transition: all 0.2s ease-out;
                }
                .image-info{
                    color: white;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    padding: 2%;
                    display: none;
                    a{
                        svg{
                            color:$f-primary-color;      
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}

.About-page-wrapper{
    .bckg{
        background: url("./assets/background-About.jpg");
        background-size: cover;
        background-position: center;
        position: absolute;
        top:0;
        left: 0;
        height: 200vh;
        width: 100vw;
        z-index: -99;
    }

    .logo{
        margin: 0 auto;
        img{
            width: 200px;
            height: auto;

            @media (min-width:300px) {
                width: 300px;
            }
            @media (min-width:400px) {
                width: 400px;
            }
        }
    }

    .About-page-content{
        width: 80%;
        margin: 20% auto;
        padding: 1%;
        color: white;
        display: flex;
        flex-direction: column;
        align-content: center;
        @media (max-width:500px) {
            width: 95%;
        }
        section{
            background:#262626;
            display: flex;
            flex-wrap: wrap;
            padding: 3.5rem 1rem;
            &:first-child{
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
            }
            &:last-child{
                display: none;
            }
            @media (max-width:500px) {
                flex-direction: column;
                &:last-child{
                    .visual-content{
                        order: 1;
                    }
                }
            }

            .text-content{
                width: 80%;
                padding:1%;;
                @media (max-width:500px) {
                    width: 100%;
                }
                h1{
                    color: $f-secondary-color;
                    font-size: 2.5rem;
                }
                p{
                    font-size: 1.3rem;
                }
            }
            .visual-content{
                width: 20%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @media (max-width:500px){
                    width: 100%;
                }
                img{
                    width: 100%;
                    height: auto;
                    margin: 0 auto;
                    object-fit: contain;
                    border-radius: 20px;
                    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.308);
                    transition: 0.5s ease-in-out;
                    &:hover{
                        @media (min-width:1000px) {
                            transform: scale(1.5);
                        }
                    }
                }
                .social{
                    display: flex;
                    justify-content: space-around;
                    padding: 5%;
                    a{
                        color: white;
                        transition: 0.5s ease-in-out;
                        svg{
                            font-size: 2rem;
                        }
                        &:hover{
                            color:$f-secondary-color;
                        }
                    }
                }
                h1{
                    text-align: center;
                    font-size: 1.5rem;
                    user-select: none;
                }
            }
    
        }
    }
}

